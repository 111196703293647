import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  AppSwitcher,
  initApp,
  PageModule,
  CartModule,
  CommonModule,
  LocationModule,
  ProductModule,
  ProductListingModule,
  ProductCardModule,
} from "@ultracommerce/react-storefront/global";

import "./i18n";
import TagManager from "react-gtm-module";
import store from "./createStore";
import "./assets/theme";
import preload from "./preload";

import { ProductService } from "./services/ProductService";
import ProductDetailsPage from "./pages/ProductDetailsPage/ProductDetailsPage.page";
import { CartLineItem } from "./modules/Cart/Components/CartLineItem/CartLineItem.component";
import { MiniCart } from "./components/MiniCart/MiniCart";
import { LocationCard } from "./modules/Location/Controls/LocationCard";
import { ProductForm } from "./modules/Product/Components/ProductForm/ProductForm.component";
import { PaymentSlide } from "./components/Checkout/Payment/Payment";
import { ShippingMethodRates } from "./components/Checkout/Fulfillment/Shipping/ShippingMethodRates";
import { ShippingSlide } from "./components/Checkout/Fulfillment/ShippingSlide";
import { PickupLocationPicker } from "./components/Checkout/Fulfillment/PickUpLocationPicker";
import { ProductCardWithDetail } from "./modules/ProductCard/Controls/ProductCardWithDetail";
import {
  FulfilmentAddressSelector,
  BillingAddress,
} from "./components/Checkout/Fulfillment/FulfilmentAddressSelector";
import { TermPayment } from "./components/Checkout/Payment/TermPayment";
import { AccountLogin } from "./components/Account/AccountLogin/AccountLogin";
import { CartCheckOut } from "./modules/Cart/Controls/CartCheckOut";
import { SlideNavigation } from "./components/Checkout/Payment/SlideNavigation";
import { ProductPrice } from "./modules/Product/Components/ProductPrice/ProductPrice.component";
import { AccountListItems } from "./components/Account/AccountLists/AccountListItems";
import { ListingSidebar } from "./modules/ProductListing/controls/ListingSidebar";
import ProductType from "./pages/ProductType/index";
import MyAccount from "./pages/MyAccount/MyAccount";
import { AccountLayout } from "./components/Account/AccountLayout/AccountLayout";
import { RouteService } from "./services/routeService.class";
import { BrandRangePreFilter } from "./components/Brand/BrandRangePreFilter";
import { OrderFulfilments } from "./components/Account/AccountOrderDetail/OrderShipments";
import { AccountImpersonation } from "./components/Account/AccountImpersonation/AccountImpersonation";
import { AccountProfile } from "./components/Account/AccountProfile/AccountProfile";
import { AccountImpersonationBanner } from "./components/Account/AccountImpersonation/AccountImpersonationBanner";
import { CreateGuestAccount } from "./components/Account/CreateGuestAccount/CreateGuestAccount";
import BrandPage from "./pages/Brand";
import FormCheckboxField from "./components/formCheckboxField";
import { AccountLists } from "./components/Account/AccountLists/AccountLists";
import { HeaderContent } from "./components/Header/HeaderLayout/HeaderContent";
import { SearchBar } from "./components/Header/SearchBar/SearchBar.component";
import { AccountOrderHistory } from "./components/Account/AccountOrderHistory/AccountOrderHistory";
import { ActionBanner } from "./components/ActionBanner/ActionBanner";
import { MenuItem } from "./components/Header/MenuItem/MenuItem";
import FeatureCategoryListings from "./components/FeaturedCategoryListings/FeaturedCategoryListings";
import { ProductTypeList } from "./components/ProductTypeList/ProductTypeList";
import { ListingBanner } from "./components/ListingBanner/ListingBanner";
import { ProductDetailGallery } from "./components/Product/ProductImageGallery/ProductDetailGallery.component";
import { CheckoutSideBar } from "./components/Checkout/CheckoutSideBar";
import { OrderNotes } from "./components/Checkout/OrderNotes";

initApp(preload);

CommonModule.MiniCart = MiniCart;
CommonModule.PaymentSlide = PaymentSlide;
CommonModule.SlideNavigation = SlideNavigation;
CommonModule.AccountLogin = AccountLogin;
CommonModule.OrderFulfilments = OrderFulfilments;
CommonModule.AccountListItems = AccountListItems;
CommonModule.AccountLists = AccountLists;
CommonModule.AccountImpersonation = AccountImpersonation;
CommonModule.AccountImpersonationBanner = AccountImpersonationBanner;
CommonModule.AccountOrderHistory = AccountOrderHistory;
CommonModule.AccountProfile = AccountProfile;
CommonModule.CreateGuestAccount = CreateGuestAccount;
CommonModule.BrandRangePreFilter = BrandRangePreFilter;
CommonModule.FormCheckboxField = FormCheckboxField;
CommonModule.HeaderContent = HeaderContent;
CommonModule.SearchBar = SearchBar;
CommonModule.ShippingMethodRates = ShippingMethodRates;
CommonModule.ShippingSlide = ShippingSlide;
CommonModule.FulfilmentAddressSelector = FulfilmentAddressSelector;
CommonModule.BillingAddress = BillingAddress;
CommonModule.PickupLocationPicker = PickupLocationPicker;
CommonModule.TermPayment = TermPayment;
CommonModule.AccountLayout = AccountLayout;
CommonModule.ActionBanner = ActionBanner;
CommonModule.MenuItem = MenuItem;
//@ts-ignore
CommonModule.ListingBanner = ListingBanner;
CommonModule.CheckoutSideBar = CheckoutSideBar;
CommonModule.OrderNotes = OrderNotes;

PageModule.ProductDetailsPage = ProductDetailsPage;
PageModule.ProductType = ProductType;
PageModule.MyAccount = MyAccount;
PageModule.Brand = BrandPage;

LocationModule.CoreControl.LocationCard = LocationCard;
CartModule.CoreComponents.CartLineItem = CartLineItem;
CartModule.CoreControl.CartCheckOut = CartCheckOut;
//@ts-ignore
ProductModule.CoreComponents.ProductForm = ProductForm;
ProductModule.CoreComponents.ProductPrice = ProductPrice;
ProductModule.CoreComponents.ProductTypeList = ProductTypeList;
ProductModule.CoreComponents.ProductDetailGallery = ProductDetailGallery;
ProductListingModule.CoreControl.ListingSidebar = ListingSidebar;
//@ts-ignore
ProductCardModule.CoreControl.ProductCardWithDetail = ProductCardWithDetail;
const tagManagerArgs = {
  gtmID: "G-P1RZDJPYTE",
};
//@ts-ignore
TagManager.initialize(tagManagerArgs);

createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
      <ServiceContextProvider customServices={{ ProductService, RouteService }}>
        <ElementContextProvider
          customComponents={{
            CommonModule,
            PageModule,
            CartModule,
            LocationModule,
            ProductModule,
            ProductListingModule,
            ProductCardModule,
          }}
        >
          <ComponentContextProvider
            customComponents={{
              cetFeaturedCategoryListing: FeatureCategoryListings,
            }}
          >
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);
